<template lang="pug">
.container.max-w-5xl
  .min-h-screen.py-52
    H1Element.mb-12 {{ $t('terms.h1') }}
    p {{ $t('terms.p_1') }}
    h3 {{ $t('terms.h3_1') }}
    p {{ $t('terms.p_2') }}
    h3 {{ $t('terms.h3_2') }}
    p {{ $t('terms.p_3') }}
    h3 {{ $t('terms.h3_3') }}
    p {{ $t('terms.p_4') }}
    h3 {{ $t('terms.h3_4') }}
    p {{ $t('terms.p_5') }}
    h3 {{ $t('terms.h3_5') }}
    p {{ $t('terms.p_6') }}
    h3 {{ $t('terms.h3_6') }}
    p {{ $t('terms.p_7') }}
    h3 {{ $t('terms.h3_7') }}
    p {{ $t('terms.p_8') }}
    h3 {{ $t('terms.h3_8') }}
    p {{ $t('terms.p_9') }}
    h3 {{ $t('terms.h3_9') }}
    p {{ $t('terms.p_10') }}
    h3 {{ $t('terms.h3_11') }}
    p {{ $t('terms.p_17') }}
    p {{ $t('terms.p_18') }}
    h3 {{ $t('terms.h3_10') }}
    p {{ $t('terms.p_11') }}
    p {{ $t('terms.p_12') }}
    p {{ $t('terms.p_13') }}
    p {{ $t('terms.p_14') }}
    p {{ $t('terms.p_15') }}
    p {{ $t('terms.p_16') }}
</template>
<script>
import H1Element from "@/components/elements/h1.element";
export default {
  name: "terms",
  components: {
    H1Element,
  },
};
</script>
<style lang="scss" scoped>
p {
  @apply text-sm text-gray-500;
}

h3 {
  @apply text-base font-semibold text-gray-600;
}

p + p {
  margin-top: 0.5rem;
}

h3 + p {
  margin-top: 1rem;
}

p + h3 {
  margin-top: 3rem;
}
</style>